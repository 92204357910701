export default function AboutUs() {
    return (
        <div className="bg-gray-50 min-h-screen">
            <div
                className="relative bg-gradient-to-r from-primaryDark to-primaryLight text-white py-36 flex items-center justify-center">
                <div className="flex items-center gap-8">
                    <div className="flex-shrink-0">
                        <img src="/resources/white_Logo.svg" alt="white logo" className="w-52"/>
                    </div>

                    <div>
                        <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-left">
                            Bienvenue chez MecaNext
                        </h1>
                        <p className="font-medium sm:text-xl text-left">
                            Une marketplace innovante dédiée à la vente et à l'achat de pièces et services mécaniques.
                        </p>
                    </div>
                </div>
            </div>

            {/* Content Section */}
            <div className="max-w-7xl mx-auto py-16 px-6 sm:px-12">
                {/* About Us Section */}
                <section className="mb-16 flex flex-col items-start">
                    <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">À Propos de Nous</h2>
                    <p className="text-center text-gray-600 text-sm mb-8 font-medium">
                        Découvrez qui nous sommes et ce que nous faisons.
                    </p>
                    <p className="font-medium text-gray-700 leading-relaxed">
                        MecaNext est une marketplace en ligne innovante dédiée à la vente et à l'achat de pièces et services mécaniques. Notre plateforme couvre une large gamme de véhicules et machines, incluant voitures, motos, équipements lourds et machines industrielles. Nous mettons en relation les clients avec un réseau de vendeurs de confiance, garantissant l'accès à des pièces et services de haute qualité. Que vous soyez un professionnel de la mécanique ou un passionné de véhicules, MecaNext propose un catalogue complet adapté à vos besoins.
                    </p>
                    <p className="font-medium text-gray-700 leading-relaxed mt-4">
                        Nous mettons l'accent sur la satisfaction des clients, en priorisant la transparence, des transactions sécurisées et un service fiable. Notre objectif est de bâtir un écosystème de confiance où les utilisateurs peuvent naviguer facilement, comparer les produits et choisir les meilleures options pour leurs besoins mécaniques.
                    </p>
                </section>

                {/* Vision Section */}
                <section className="mb-16 flex flex-col items-start">
                    <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">Notre Vision</h2>
                    <p className="text-center font-medium text-gray-600 text-sm mb-8">
                        Notre engagement pour l'avenir de l'industrie mécanique.
                    </p>
                    <p className="font-medium text-gray-700 leading-relaxed">
                        Chez MecaNext, notre vision est de révolutionner l'industrie mécanique en créant une marketplace numérique fluide et inclusive. Nous visons à devenir la plateforme leader en Algérie, en offrant aux entreprises et aux particuliers des outils innovants pour acheter, vendre et découvrir des pièces mécaniques de qualité. Nous nous engageons à favoriser la croissance, à soutenir des pratiques durables et à améliorer l'expérience client à travers l'innovation et l'intégration technologique.
                    </p>
                    <p className="font-medium text-gray-700 leading-relaxed mt-4">
                        Nous croyons en la création d'un écosystème communautaire axé sur la confiance, la qualité et l'efficacité. MecaNext aspire à combler le fossé entre fournisseurs et clients, rendant le processus de recherche et d'achat de pièces mécaniques plus accessible et plus efficace que jamais..
                    </p>
                </section>

                <section className="flex flex-col items-start">
                    <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">Contactez-Nous</h2>
                    <p className="text-center font-medium text-gray-600 text-sm mb-8">
                        Nous sommes là pour répondre à toutes vos questions.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
                        <div>
                            <p className="font-medium text-gray-700 mb-4">
                                <strong className="text-lg font-bold text-black">Adresse du Siège :</strong><br/>
                                Cité Houari Boumédiene, Béjaïa, Algérie
                            </p>
                            <p className="text-lg text-gray-700 mt-4">
                                <strong>Email :</strong> <a href="mailto:contact@mecanex-dz.com"
                                                            className="text-blue-600 underline">contact@mecanext-dz.com</a>
                            </p>
                        </div>
                        <div>
                            <p className="font-medium text-gray-700 mb-2">
                                <strong className="font-bold text-black text-lg">Numéros de Téléphone :</strong>
                            </p>
                            <ul className="list-disc font-medium pl-6 text-gray-700">
                                <li>+213 557 27 05 39</li>
                                <li>+213 662 05 36 04</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
