import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router";

export default function TopBanner() {
    const {t, i18n} = useTranslation();

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage); // Save to localStorage
    };

    const navigate = useNavigate();

    return (
        <div className="min-h-10 w-full bg-black flex flex-row items-center px-2 sm:px-9 justify-between">
            <div className="flex flex-row items-center gap-4 sm:gap-7">
                <button
                    onClick={() => navigate("/about-us")}
                    className="flex flex-row items-center gap-2 bg-transparent border-0 cursor-pointer"
                    aria-label={t('phone')}
                >
                    <img className="white_filter w-5" src="/resources/round_phone.svg" alt={t('phone_icon')}/>
                    <p
                        className="lg:block hidden text-white font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                        {t('phone')}
                    </p>
                </button>
                <div className="flex flex-row items-center gap-2">
                    <img className="white_filter w-5" src="/resources/reader-following.svg" alt="follow"/>
                    <p className="lg:block hidden text-white font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                        {t('header.followOrder')}
                    </p>
                </div>
            </div>
            <div className="flex flex-row items-center justify-end w-fit lg:w-[20vw]">
                <select
                    className="font-medium text-white bg-black outline-none focus:outline-none cursor-pointer"
                    onChange={handleLanguageChange}
                    value={i18n.language}
                >
                    <option value="fr">Francais</option>
                    <option value="en">English</option>
                </select>
            </div>
        </div>
    );
}
