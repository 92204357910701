import {createBrowserRouter, createRoutesFromElements, Navigate, Route} from "react-router-dom";
import Marketplace from "../pages/Marketplace";
import Admin from "../pages/AdminSpace/Pages/index";
import MarketplaceRoutes from "./MarketplaceRoutes/MarketplaceRoutes";
import ProductDetails from "../pages/ProductDetails";
import WishList from "../pages/WishList";
import CartPage from "../pages/CartPage";
import Login from "../pages/Authentication/Pages/login";
import SignUp from "../pages/Authentication/Pages/SignUp";
import SearchPage from "../pages/SearchPage";
import PrivacyPolicy from "../pages/privacyPolicy/privacyPolicy";
import ProfilePage from "../pages/userProfile/index";
import AuthenticatedRoutes from "./AuthenticatedRoutes/AuthenticatedRoutes";
import ShopPage from "../pages/ShopPage";
import NonMarketPlaceRoutes from "./NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import SellerSpace from "../pages/SellerSpace";
import AddProduct from "../pages/SellerSpace/pages/AddProducts/AddProduct";
import SellerSpaceRoutes from "./SellerSpaceRoutes/SellerSpaceRoutes";
import AddPacks from "../pages/SellerSpace/pages/AddPacks/AddPacks";
import SellerForm from "../pages/AdminSpace/components/Forms/pages/SellerForm";
import BuyerForm from "../pages/AdminSpace/components/Forms/pages/BuyerForm";
import SecretaryForm from "../pages/AdminSpace/components/Forms/pages/SecretaryForm";
import AdminRoutes from "./AdminRoutes/AdminRoutes";
import AddService from "../pages/SellerSpace/pages/AddService/AddService";
import CreateShop from "../pages/SellerSpace/pages/CreateShop/CreateShop";
import AddVehiclePage from "../pages/AddVehiclePage";
import ServiceDetails from "../pages/ServiceDetails";
import ReservationService from "../pages/SellerSpace/pages/ReservationService";
import Schedule from "../pages/ServiceSchduler/index";
import ClientReservation from "../pages/clientReservations/ClientReservation";
import VehicleDetailsPage from "../pages/VehiculeDetails";
import BoatDetailsPage from "../pages/BoatDetails";
import NotificationsPage from "../pages/NotificationsPage";
import MyVehicules from "../pages/MyVehicules/MyVehicules";
import '../i18n';
import MachineDetailsPage from "../pages/MachineDetails/MachineDetailsPage";
import OrderTable from "../pages/userProfile/components/orders";
import AboutUs from "../pages/SellerSpace/AboutUs";
export const reactRouter = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<MarketplaceRoutes/>}>
                <Route path={"/"} element={<Marketplace/>}/>
                <Route path={"/product/:id"} element={<ProductDetails/>}/>
                <Route path={"/service/:id"} element={<ServiceDetails/>}/>
                <Route path={"/vehicle/:id"} element={<VehicleDetailsPage/>}/>
                <Route path={"/boat/:id"} element={<BoatDetailsPage/>}/>
                <Route path={"/engin/:id"} element={<MachineDetailsPage/>}/>
                <Route path="/wishlist" element={<WishList/>}/>
                <Route path={"/cart"} element={<CartPage/>}/>
                <Route path="/search" element={<SearchPage/>}/>
                <Route path={"/services/:serviceId/schedule"} element={<Schedule/>}/>
                <Route path={"/shop/:id"} element={<ShopPage/>}/>
                <Route path={"/about-us"} element={<AboutUs/>}/>
            </Route>

            <Route element={<NonMarketPlaceRoutes/>}>
                <Route element={<AuthenticatedRoutes/>}>
                    <Route path={"/ajouter-vehicule"} element={<AddVehiclePage/>}/>
                    <Route element={<AdminRoutes/>}>
                        <Route path={"/admin"} element={<Admin/>}/>
                        <Route path={"/admin/addSeller"} element={<SellerForm/>}/>
                        <Route path={"/admin/addBuyer"} element={<BuyerForm/>}/>
                        <Route path={"/admin/addSecretary"} element={<SecretaryForm/>}/>
                        <Route path={"/admin/Seller/:id"} element={<SellerForm/>}/>
                        <Route path={"/admin/Buyer/:id"} element={<BuyerForm/>}/>
                        <Route path={"/admin/Secretary/:id"} element={<SecretaryForm/>}/>
                    </Route>
                    <Route path={"/profile"} element={<ProfilePage/>}/>
                    <Route path={"/my-reservations"} element={<ClientReservation/>}/>
                    <Route path={"/my-orders"} element={<OrderTable/>}/>
                    <Route path={"/create-shop"} element={<CreateShop/>}/>
                    <Route element={<SellerSpaceRoutes/>}>
                        <Route path={"/seller/:id"} element={<SellerSpace/>}/>
                        <Route path={"/seller/:id/reservations"} element={<ReservationService/>}/>
                        <Route path={"/seller/:id/addProduct"} element={<AddProduct/>}/>
                        <Route path={"/seller/:id/addPacks"} element={<AddPacks/>}/>
                        <Route path={"/seller/:id/addService"} element={<AddService/>}/>
                    </Route>
                    <Route path={"/notifications"} element={<NotificationsPage/>}/>
                    <Route path={"/mes_annonce"} element={<MyVehicules/>}/>
                </Route>
            </Route>

            <Route path={"/login"} element={<Login/>}/>
            <Route path={"/signup"} element={<SignUp/>}/>
            <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
            <Route path="*" element={<Navigate to="/" replace />} />

        </>
    )
)